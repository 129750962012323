import { get, isEmpty, isEqual, keyBy, noop, sumBy, without } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import scroll from "scroll";
const page = require("scroll-doc")();
import * as styles from "./index.module.scss";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { PAYMENT_ID } from "../../api-services/constants";
import { recache, spreedlyTokenize } from "../../api-services/spreedlyAPI";
import { CUSTOM_TIP_MODE, PAYMENT } from "../../utils/constants";
import AppContainer from "../AppContainer";
import ChargeCardToggle from "../charge-card-toggle";
import ContactDetails from "../ContactDetails";
import GiftCardSelect from "../gift-card-select";
import PaymentMethodsSelect from "../payment-methods-select";
import PaymentAPIScriptLoader, {
    shouldLoadScript
} from "../PaymentAPIScriptLoader";
import PaymentMethodInput from "../PaymentMethodInput";
import PriceDetails from "../TotalPrice";
import SwipeableTemporaryDrawer from "./swipable-temporary-drawer";
import TipSelect from "./tip-select";
import { getParams, setParams } from "../../utils/location";
import ToastHostedAPI from "../../api-services/toastHosted";

let elementAnimation = null;

export default function PaymentForm(props) {
  const {
    appStyles,
    pageContext,
    T,
    app: { keyboardOpen },
    user,
    order: {
      servingOptionTipPercentage,
      servingOptionTipCustomValue,
    },
    order,
    location,
    paymentTypeDetails,
    loadPaymentMethods,
    onSubmit,
    onValidate = noop,
    onValidationCompleted = noop,
    openAuthView,
    setDefaultPaymentMethod,
    priceDetails,
    errorMessage,
    checkDetails,
    stripe,
    elements,
    openPayment
  } = props;
  let ToastAPI = null;

  const {
    business: { currencySymbol, areaCode, openChargeCard, name: businessName },
    businessAppConfiguration: { idRequired, enableValutecGiftCards },
  } = pageContext;

  const [chargeCardDeselected, setChargeCardDeselected] = useState(true);
  const [openPaymentMethods, setOpenPaymentMethods] = useQueryParam(
    "openPaymentMethods",
    BooleanParam,
  );
  const handleOpenPaymentOptions = useCallback(() =>
    setOpenPaymentMethods(true),
  );

  const handleClosePaymentOptions = useCallback(() =>
    setOpenPaymentMethods(false),
  );

  const { requireZipCode } = paymentTypeDetails || {};
  const [fieldsState, setFieldsState] = useState({
    ...FIELDS.reduce(
      (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
      {},
    ),
    ...userToFields(user),
  });

  const [validatingForm, setValidatingForm] = useState();
  const [animateErrorComponent, setAnimateErrorComponent] = useState(false);
  const [errorComponent, setErrorComponent] = useState();

  // useEffect(() => {
  //   if (validatingForm) {
  //     onValidate();
  //   } else {
  //     onValidationCompleted();
  //   }
  // }, [validatingForm]);

  const [giftCardsToRedeem, setGiftCardsToRedeem] = useState([]);

  const [shouldLoadPayment, setShouldLoadPayment] = useState(
    shouldLoadScript(paymentTypeDetails),
  );

  useDeepCompareEffect(() => {
    if (
      !shouldLoadPayment &&
      !paymentLoaded &&
      shouldLoadScript(paymentTypeDetails)
    ) {
      setShouldLoadPayment(true);
    }
  }, [paymentTypeDetails]);

  const [paymentLoaded, setPaymentLoaded] = useState(false);

  useEffect(() => {
    console.log("loading payment methods");
    loadPaymentMethods(get(paymentTypeDetails, "paymentType"));
  }, [user.loggedIn]);

  useEffect(() => {
    if (paymentTypeDetails.paymentType !== PAYMENT_ID.STRIPE || isEmpty(user.paymentMethods.data)) return;

    const {
      token,
      displayString,
      expirationMonth,
      expirationYear,
    } = get(user, "paymentMethods.data.[0]");

    setFieldsState({
      [PAYMENT.PAYMENT_METHOD_INPUT]: {
        creditCard: {
          token,
          month: expirationMonth,
          year: expirationYear,
          last_four_digits: displayString,
        },
      },
    });
  }, [user.paymentMethods.data])

  const [cardsRefs, setCardsRefs] = useState({});
  const [fieldsRefs, setFieldsRefs] = useState({});

  const onInputError = useCallback(
    (fieldName) => (error) =>
      setFieldsState({ ...fieldsState, [`${fieldName}_ERROR`]: error }),
    [fieldsState],
  );

  const onInputValid = useCallback(
    (fieldName) => (value) =>
      setFieldsState({
        ...fieldsState,
        [`${fieldName}_ERROR`]: null,
        [fieldName]: value,
      }),
    [fieldsState],
  );

  const registerInput = useCallback(
    (fieldName) => (ref) =>
      ref &&
      fieldsRefs[fieldName] !== ref &&
      setFieldsRefs({ ...fieldsRefs, [fieldName]: ref }),
    [fieldsRefs],
  );

  const refCard = useCallback(
    (cardKey) => (ref) =>
      ref &&
      cardsRefs[cardKey] !== ref &&
      setCardsRefs({ ...cardsRefs, [cardKey]: ref }),
    [cardsRefs],
  );

  // isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  const getInputPropsFor = useCallback(
    (inputId, refKey = "refEl") => ({
      [refKey]: registerInput(inputId),
      onValid: onInputValid(inputId),
      onError: onInputError(inputId),
      rtl: appStyles.rtl,
      T,
    }),
    [fieldsRefs],
  );

  const scrollToErrorComponent = () => {
    scroll.top(
      page,
      get(cardRefs, `[${errorComponent}].offsetTop`) - 150,
      () => {
        setAnimateErrorComponent(true);
        clearTimeout(elementAnimation);
        elementAnimation = setTimeout(
          () => setAnimateErrorComponent(false),
          300,
        );
      },
    );
  };

  const isCurrentPaymentMethodRequireCVV = () => {
    if (!user.loggedIn) {
      return true;
    }
    const currentPaymentMethod = _.get(user, "paymentMethods.data[0]");
    if (!currentPaymentMethod) {
      return true;
    }

    // in case of a null this is was not defined therefore we will require cvv for any case.
    return currentPaymentMethod.cvvMandatory !== false;
  };

  const preTokenizeOrRecacheIfNeeded = (customerDetails, paymentInput) => {
    if (freeOrder) {
      return Promise.resolve();
    }

    if (paymentTypeDetails.paymentType === PAYMENT_ID.STRIPE) {
      if (
        fieldsState[PAYMENT.PAYMENT_METHOD_INPUT] &&
        fieldsState[PAYMENT.PAYMENT_METHOD_INPUT].creditCard
      ) {
        const {
          token,
          last_four_digits: last4,
          month: exp_month,
          year: exp_year,
        } = fieldsState[PAYMENT.PAYMENT_METHOD_INPUT].creditCard;

        return Promise.resolve({
          tokenResponse: { token, last4, exp_month, exp_year },
        });
      }

      return elements.submit().then(() =>
        stripe
          .createPaymentMethod({
            elements: elements,
            params: {
              billing_details: {
                name: customerDetails.fullName,
                phone: customerDetails.phoneNumber,
                email: customerDetails.email,
              },
            },
          })
          .then((res) => {
            const {
              id,
              card: { exp_month, exp_year, last4 },
              billing_details,
            } = res.paymentMethod;
            
            // hack due to existing validation code of payment method input
            return {
              tokenResponse: { token: id, last4, exp_month, exp_year },
              creditCard: {
                token: id,
                month: exp_month,
                year: exp_year,
                zipCode: billing_details.address.postal_code,
                last_four_digits: last4,
              },
            };
          })
      );
    }

    if (paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED) {
      return ToastAPI.addPaymentMethod({ total: priceDetails.total, tipAmount }).then((paymentMethodId) => {
        return ToastAPI.confirmPayment().then((payment) => {
          console.log("CONFIRMED PAYMENT:", payment);

          return {
            tokenResponse: {
              paymentId: paymentMethodId,
              paymentToken: order.openPaymentTokens.data.paymentId,
            },
            creditCard: {
              token: paymentMethodId,
            },
          };
        });
      });
    }

    if (
      _.includes(
        [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
        paymentTypeDetails?.paymentType,
      ) &&
      isCurrentPaymentMethodRequireCVV()
    ) {
      if (paymentInput.spreedlyRecache) {
        console.log("recache");

        return recache();
      }

      return spreedlyTokenize({
        fullName: customerDetails.fullName,
        ..._.pick(paymentInput.creditCard, ["month", "year", "zipCode"]),
      });
    }

    return Promise.resolve();
  };

  const currentPaymentMethod = get(user, "paymentMethods.data[0]");

  const requireCVV = useMemo(() => {
    if (!user.loggedIn) {
      return true;
    }

    if (!currentPaymentMethod) {
      return true;
    }
    return currentPaymentMethod.cvvMandatory !== false;
  }, [user.loggedIn, currentPaymentMethod]);

  const showChargeCardToggle = priceDetails.amountApplicableFromBalance > 0;
  const hasDiscountFromSelectedCouponBatch = priceDetails.totalDiscount > 0;
  const hasAppliedChargeCardAmount =
    !chargeCardDeselected && showChargeCardToggle;
  const hasDiscounts =
    hasDiscountFromSelectedCouponBatch || hasAppliedChargeCardAmount;

  const totalAfterDiscounts = hasDiscountFromSelectedCouponBatch
    ? priceDetails.priceAfterDiscount
    : priceDetails.total;

  const amountDueBeforeExternalChargeCard = hasDiscounts
    ? chargeCardDeselected
      ? totalAfterDiscounts
      : totalAfterDiscounts - priceDetails.amountApplicableFromBalance
    : priceDetails.total;

  const totalExternalGiftCardRedeemAmount = sumBy(
    giftCardsToRedeem,
    "redeemAmount",
  );

  const [customTipOption, setCustomTipOption] = useState(false);

  const tipAmount = customTipOption && servingOptionTipCustomValue
      ? Number(servingOptionTipCustomValue) || 0
      : priceDetails.total * servingOptionTipPercentage
    || 0;

  const amountFromCreditCard =
    amountDueBeforeExternalChargeCard - totalExternalGiftCardRedeemAmount;

  const amountFromCreditCardWithTip = amountFromCreditCard + tipAmount;

  const freeOrder = amountFromCreditCard === 0;

  // console.log({ tipAmount, selectedTipOption });
  
  useEffect(() => {
    if (paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED) {
      if (!priceDetails.total) return;

      if (paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED && paymentLoaded) {
        ToastAPI = new ToastHostedAPI();
      }

      openPayment({
        amount: (priceDetails.total + tipAmount) * 100,
        user,
        paymentTypeIdentifier: paymentTypeDetails?.paymentType,
        tipAmount: tipAmount * 100 || 0,
        purchaseEventId: get(checkDetails, "purchaseEvent.id"),
        branchId: get(checkDetails, "check.branchId", "")
      });
    }
  }, [priceDetails.total, tipAmount])

  useEffect(() => {
    if (freeOrder && openPaymentMethods) {
      handleClosePaymentOptions();
    }
  }, [freeOrder]);

  const handleSubmit = () => {
    console.log("submit pay for  check");
    if (shouldLoadPayment && !paymentLoaded) {
      console.error(
        "not handled, payment script should be loaded but it failed to load",
      );
      return;
    }

    setValidatingForm(true);
    const fields = freeOrder
      ? without(FIELDS, PAYMENT.PAYMENT_METHOD_INPUT)
      : FIELDS;
    Promise.all(
      _.map(
        fields,
        (field) =>
          new Promise((resolve, reject) => {
            console.log({ fieldsRefs, field });
            fieldsRefs[field].validate((err, value) => {
              setFieldsState({
                ...fieldsState,
                [field]: value,
                [errorkey(field)]: err,
              });
              if (err) {
                return resolve({ field, err });
              }
              return resolve({ field, value });
            });
          }),
      ),
    )
      .then((res) => {
        const results = keyBy(res, "field");
        console.log({ results });
        const customerDetails = {
          email: results.EMAIL.value,
          phoneNumber: results.PHONE_NUMBER.value,
          fullName: results.NAME.value,
        };

        const paymentInputValue =
          !freeOrder && results.PAYMENT_METHOD_INPUT.value;

        preTokenizeOrRecacheIfNeeded(customerDetails, paymentInputValue)
          .then((tokenResponse) => {
            const amountFromChargeCard =
              (!chargeCardDeselected &&
                priceDetails.amountApplicableFromBalance) ||
              0;

            console.log({ giftCardsToRedeem });

            onSubmit({
              // hack due to existing validation code of payment method input
              ...([PAYMENT_ID.STRIPE, PAYMENT_ID.TOAST_HOSTED].includes(paymentTypeDetails.paymentType) ? tokenResponse : { tokenResponse }),
              customerDetails,
              ...paymentInputValue,
              paymentTypeDetails,
              amountFromCreditCard: amountFromCreditCardWithTip,
              amountFromChargeCard,
              giftCardsToRedeem,
              tipAmount,
            });
            setValidatingForm(false);
          })
          .catch((err) => {
            console.log(err);
            setValidatingForm(false);
          });
        // if (!_.every(CONTACT_DETAILS_FIELDS, this.isValidField)) {
        //   this.setState(
        //     {
        //       errorComponent: CONTACT_DETAILS,
        //       validatingForm: false,
        //     },
        //     () => {
        //       this.scrollToErrorComponent();
        //     },
        //   );
        //   console.log("Invalid Form - contact details");
        //   return;
        // }
        // if (!this.isValidField(PAYMENT.PAYMENT_METHOD_INPUT)) {
        //   this.setState(
        //     { errorComponent: PAYMENT_DETAILS, validatingForm: false },
        //     () => {
        //       this.scrollToErrorComponent();
        //     },
        //   );
        //   console.log("Invalid Form - payment details");
        //   return;
        // }
        //
        // console.log("Valid Form");
        setValidatingForm(false);
      })
      .catch((err) => {
        console.log(err);
        setValidatingForm(false);
      });
  };
  return (
    <>
    <AppContainer.Content
          appStyles={appStyles}
          classNames={styles.DesktopGutters}
        >
      {shouldLoadPayment && (
        <PaymentAPIScriptLoader
          paymentTypeDetails={paymentTypeDetails}
          onLoad={() => setPaymentLoaded(true)}
        />
      )}
      <TipSelect
        appStyles={appStyles}
        T={T}
        enableCustomTip
        currencySymbol={currencySymbol}
        customTipOption={customTipOption}
        setCustomTipOption={setCustomTipOption}
      />

      {showChargeCardToggle && (
        <ChargeCardToggle
          T={T}
          appStyles={appStyles}
          isChargeCardDeselected={chargeCardDeselected}
          setChargeCardDeselected={setChargeCardDeselected}
          chargeCardBalance={get(priceDetails, "balance.amount")}
          currencySymbol={currencySymbol}
          // canUserLoadChargeCard={canUserLoadChargeCard}
          businessName={businessName}
        />
      )}

      <GiftCardSelect
        loading={false}
        user={user}
        giftCardsToRedeem={giftCardsToRedeem}
        T={T}
        setGiftCardsToRedeem={setGiftCardsToRedeem}
        appStyles={appStyles}
        currencySymbol={currencySymbol}
        onConnectGiftCard={noop}
        addExternalGiftCardToAccount={noop}
        totalExternalGiftCardRedeemAmount={totalExternalGiftCardRedeemAmount}
        amountDue={amountDueBeforeExternalChargeCard}
        openAuthView={openAuthView}
        registerInput={registerInput}
        enableValutecGiftCards={enableValutecGiftCards}
      />

      <ContactDetails
        T={T}
        appStyles={appStyles}
        hasErrors={errorComponent === CONTACT_DETAILS}
        animateError={
          errorComponent === CONTACT_DETAILS && animateErrorComponent
        }
        refEl={refCard(CONTACT_DETAILS)}
        inputProps={{
          [PAYMENT.NAME]: {
            ...getInputPropsFor(PAYMENT.NAME),
            initialValue: user.loggedIn
              ? user.userDetails.data.name
              : fieldsState[PAYMENT.NAME],
          },
          [PAYMENT.PHONE_NUMBER]: {
            ...getInputPropsFor(PAYMENT.PHONE_NUMBER),
            value: user.loggedIn && user.userDetails.data.phoneNumber,
            disabled: user.loggedIn,
          },
          [PAYMENT.EMAIL]: {
            ...getInputPropsFor(PAYMENT.EMAIL),
            initialValue: user.loggedIn
              ? user.userDetails.data.email
              : fieldsState[PAYMENT.EMAIL],
          },
        }}
        title={T("Your Details")}
        getInputPropsFor={getInputPropsFor}
        areaCode={areaCode}
      />

      {!freeOrder && (
        <div
          style={{
            margin: 16,
          }}
        >
          <PaymentMethodInput
            user={user}
            appStyles={appStyles}
            T={T}
            getInputPropsFor={getInputPropsFor}
            // changeTargetURL={setParams("/payment-methods", {
            //   ...getParams(location),
            //   backPath: "/scan",
            // })}
            changeTargetURL={setParams("/payment-methods", {
              ...getParams(location),
              backPath: setParams("/scan", getParams(location)),
              selectedBranchId: get(checkDetails, "check.branchId", ""),
            })}
            idRequired={idRequired}
            requireZipCode={requireZipCode}
            requireCVV={requireCVV}
            ref={registerInput(PAYMENT.PAYMENT_METHOD_INPUT)}
            hasErrors={errorComponent === PAYMENT_DETAILS}
            animateError={
              errorComponent === PAYMENT_DETAILS && animateErrorComponent
            }
            refEl={refCard(PAYMENT_DETAILS)}
            paymentTypeDetails={paymentTypeDetails}
            paymentScriptLoaded={paymentLoaded}
            order={order}
          />
          <SwipeableTemporaryDrawer
            T={T}
            open={openPaymentMethods}
            onClose={handleClosePaymentOptions}
            anchor="left"
            title={T("Payment Methods")}
            // onOpen={toggleDrawer(anchor, true)}
          >
            <PaymentMethodsSelect
              pageContext={pageContext}
              T={T}
              appStyles={appStyles}
              onChange={setDefaultPaymentMethod}
            />
          </SwipeableTemporaryDrawer>
        </div>
      )}
      <PriceDetails
        priceDetails={{
          ...priceDetails,
          tipAmount,
          total: priceDetails.total + tipAmount,
        }}
        appStyles={appStyles}
        T={T}
        shouldDisplayServiceOptionTip
        style={{paddingBottom: "50px"}}
      />

      <AppContainer.Footer
        relativePosition={keyboardOpen}
        appStyles={appStyles}
        center
        height={true}
      >
        <AppContainer.Footer.Button
          disabled={errorMessage}
          spread={amountFromCreditCardWithTip > 0}
          onClick={handleSubmit}
          appStyles={appStyles}
          loading={validatingForm}
        >
          <span>
            {errorMessage
              ? errorMessage
              : hasDiscounts
              ? amountFromCreditCardWithTip
                ? T("Pay")
                : T("Redeem with Rewards")
              : T("Pay now")}
          </span>

          {amountFromCreditCardWithTip > 0 && (
            <span>{`${currencySymbol}${amountFromCreditCardWithTip.toFixed(
              2,
            )}`}</span>
          )}
        </AppContainer.Footer.Button>
      </AppContainer.Footer>
      </AppContainer.Content>
    </>
  );
}

const CONTACT_DETAILS_FIELDS = [
  PAYMENT.EMAIL,
  PAYMENT.PHONE_NUMBER,
  PAYMENT.NAME,
];

const FIELDS = [...CONTACT_DETAILS_FIELDS, PAYMENT.PAYMENT_METHOD_INPUT];

const PAYMENT_DETAILS = "PAYMENT_DETAILS";
const CONTACT_DETAILS = "CONTACT_DETAILS";

const errorkey = (key) => `${key}_ERROR`;

const userToFields = (user) =>
  user.loggedIn
    ? {
        [PAYMENT.NAME]: user.userDetails.data.name,
        [PAYMENT.PHONE_NUMBER]: user.userDetails.data.phoneNumber,
        [PAYMENT.EMAIL]: user.userDetails.data.email,
      }
    : null;
